import React from 'react';
import {FieldHelperProps} from 'formik';

import FormikInput from './FormikInput';
import {InputProps} from '../types';

const ALL_EXCEPT_NUMBERS_REGEX = /[^-\d]/g;

type Props = InputProps & {
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, formikActions: FieldHelperProps<any>) => void
  includeSign?: boolean
  includeCommas?: boolean
}

// display value of number
export const formatNumber = (value: string | number | null, includeSign = false, includeCommas: boolean = true): string => {
  if (value === null || value === '') {
    return '';
  } else if (value === '-') {
    return value;
  } else if (typeof value === 'number') {
    if (includeSign) {
      const sign = value > 0 ? '+' : '';
      return includeCommas ? sign + value.toLocaleString() : sign + value;
    } else {
      return includeCommas ? value.toLocaleString() : value.toString();
    }
  } else {
    const onlyDigits = value.replace(ALL_EXCEPT_NUMBERS_REGEX, '');
    const numberValue = Number(onlyDigits);
    if (includeSign) {
      const sign = numberValue > 0 ? '+' : '';
      if(isFinite(numberValue)) {
        return includeCommas ? sign + numberValue.toLocaleString() : sign + numberValue.toString();
      } else {
        return '';
      }
    } else {
      if(isFinite(numberValue)) {
        return includeCommas ? numberValue.toLocaleString() : numberValue.toString();
      } else {
        return '';
      }
    }
  }
};

// set formik value
export const setNumberValue = (value: string | number | null): string | number | null => {
  if (value === null) {
    return null;
  } else if (value === '-') {
    return value;
  } else if (typeof value === 'number') {
    return value;
  } else {
    const onlyDigits = value.replace(ALL_EXCEPT_NUMBERS_REGEX, '');
    const numberValue = Number(onlyDigits);
    return isFinite(numberValue) && onlyDigits !== '' ? numberValue : null;
  }
};

const FormikNumberInput = ({
                             name,
                             onChange,
                             includeSign,
                             includeCommas,
                             ...formikInputProps
                           }: Props) => {

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => {
    if (onChange) {
      onChange(e, fieldActions);
    }
    fieldActions.setValue(setNumberValue(e.target.value));
  };

  return <FormikInput {...formikInputProps}
                      name={name}
                      type="text"
                      format={(value: string | number) => formatNumber(value, includeSign, includeCommas)}
                      onChange={handleOnChange}
                      noFormikOnChange={true}
  />;
};

export default FormikNumberInput;