import React, {ReactNode, useCallback, useState} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import AlertsContext from './AlertsContext';
import {Alert} from '../types';

type Props = {
  timeout?: number
  children: ReactNode
}

const AlertsProvider = ({
                          timeout = 5000,
                          children
                        }: Props) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const removeAlert = useCallback(
    (alertId: number) => {
      setAlerts((prevAlerts) => prevAlerts.filter((alert: Alert) => alert.id !== alertId));
    }, [setAlerts]
  );

  const add = useCallback(
    (icon: ReactNode, header: ReactNode, message: string, disableTimeout: boolean = false) => {
      const alertId = new Date().getTime();
      const alert: Alert = {
        id: alertId,
        header: header,
        icon: icon,
        message: message
      };
      setAlerts((alerts: Alert[]) => alerts.concat(alert));

      if (!disableTimeout) {
        setTimeout(() => removeAlert(alertId), timeout);
      }
    },
    [setAlerts, removeAlert, timeout]
  );

  const showSuccessAlert = useCallback(
    (message: string, disableTimeout?: boolean) => {
      add(
        <FontAwesomeIcon icon="check" className="text-success"/>,
        <span className="text-success text-uppercase">Success</span>,
        message,
        disableTimeout
      );
    },
    [add]
  );

  const showErrorAlert = useCallback(
    (message: string, disableTimeout = true) => {
      add(
        <FontAwesomeIcon icon="times" className="text-danger"/>,
        <span className="text-danger text-uppercase">Failure</span>,
        message,
        disableTimeout
      );
    },
    [add]
  );

  return (
    <AlertsContext.Provider value={{alerts, removeAlert, showSuccessAlert, showErrorAlert, timeout}}>
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;