import Cookies from 'js-cookie';

import HttpMethod from './HttpMethod';
import {downloadUtils} from '../util';

type FetchConfig = {
  method?: HttpMethod
  headers?: Record<string, string>
  credentials?: RequestCredentials
  body?: BodyInit
  queryParams?: Record<string, string | number>
}

type RestApiHelperConfig = {
  path: string
  jwtKey?: string
  lastActivityCookieName?: string
  lastActivityCookieDomain?: string
  unauthorizedHandler?: () => void,
  defaultFetchConfig?: FetchConfig
}

type AdditionalOptions = {
  ignoreLastActivity?: boolean // Set to true to prevent last activity from being updated in local storage.
  suppressUnauthorizedHandler?: boolean // Set to true to prevent the configured unauthorized handler from running.
}

class RestApiHelper {
  private readonly _path: string;
  private readonly _jwtKey: string;
  private readonly _lastActivityCookieName: string | undefined;
  private readonly _lastActivityCookieDomain: string | undefined;
  private readonly _unauthorizedHandler?: () => void;
  private readonly _defaultFetchConfig?: FetchConfig;
  private readonly _buildUrl: (path: string, fetchConfig: FetchConfig) => string;
  private readonly _mergeFetchConfig: (suppliedFetchConfig: FetchConfig) => RequestInit;
  private readonly _executeRequest: (path: string, fetchConfig: FetchConfig, options: AdditionalOptions) => Promise<Response>;
  private readonly _handleResponseError: (response: Response, options: AdditionalOptions) => Promise<string | Response>;
  private readonly _handleJsonResponse: <T>(path: string, fetchConfig: FetchConfig, options?: AdditionalOptions) => Promise<T>;
  private readonly _handleTextResponse: (path: string, fetchConfig: FetchConfig, options?: AdditionalOptions) => Promise<string>;
  private readonly _handleEmptyResponse: (path: string, fetchConfig: FetchConfig, options?: AdditionalOptions) => Promise<Response>;
  private readonly _handleBlobResponse: (path: string, fetchConfig: FetchConfig, options?: AdditionalOptions) => Promise<Response>;

  constructor(config: RestApiHelperConfig) {
    this._path = config.path;
    this._jwtKey = config.jwtKey ? config.jwtKey : 'JWT';
    this._lastActivityCookieName = config.lastActivityCookieName;
    this._lastActivityCookieDomain = config.lastActivityCookieDomain;
    this._unauthorizedHandler = config.unauthorizedHandler;
    this._defaultFetchConfig = config.defaultFetchConfig;

    this._buildUrl = (path, fetchConfig) => {
      const urlToUse = new URL(path, this._path);
      if (fetchConfig.queryParams) {
        // parse any query params that have number values and convert to string for URLSearchParams
        const parsedParams: Record<string, string> = {};
        const queryParams = fetchConfig.queryParams;
        Object.keys(queryParams)
          .forEach(key => parsedParams[key] = queryParams[key].toString());
        urlToUse.search = new URLSearchParams(parsedParams).toString();
      }
      return urlToUse.toString();
    };

    this._mergeFetchConfig = (suppliedFetchConfig: FetchConfig): RequestInit => {
      const defaultHeaders: Record<string, string> = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(this._jwtKey)}`
      };

      const mergedHeaders = {...defaultHeaders, ...suppliedFetchConfig.headers};
      // For FormData objects, delete the content type if it was set or was a default. This is because the Fetch API
      // will need to set its own boundary.
      if (suppliedFetchConfig.body && suppliedFetchConfig.body instanceof FormData) {
        delete mergedHeaders['Content-Type'];
      }
      return {
        ...this._defaultFetchConfig,
        method: suppliedFetchConfig.method,
        headers: mergedHeaders,
        body: suppliedFetchConfig.body
      };
    };

    this._executeRequest = async (path: string, fetchConfig: FetchConfig, options: AdditionalOptions): Promise<Response> => {
      // Unless the request shouldn't be considered a request made by the user (refresh token request, notification
      // list request, etc.), then update last activity for session tracking if cookie information was provided. If this
      // isn't provided the user of RestApiHelper must manage this another way.
      if (!options.ignoreLastActivity && this._lastActivityCookieName && this._lastActivityCookieDomain) {
        Cookies.set(this._lastActivityCookieName,
          new Date().toISOString(),
          {
            domain: this._lastActivityCookieDomain,
            secure: window.location.protocol.startsWith('https'),
            expires: 1 // expires in 1 day. Also gets removed when an explicit sign out or timeout happens
          }
        );
      }
      const url = this._buildUrl(path, fetchConfig);
      const mergedFetchConfig = this._mergeFetchConfig(fetchConfig);
      return await fetch(url, mergedFetchConfig);
    };

    this._handleResponseError = async (response: Response, options: AdditionalOptions): Promise<string | Response> => {
      console.error(response);
      let error;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        error = await response.json();
      } else {
        error = response;
      }
      if (response.status === 401 && this._unauthorizedHandler && !options.suppressUnauthorizedHandler) {
        this._unauthorizedHandler();
      }
      return error;
    };

    this._handleJsonResponse = async <T>(path: string, fetchConfig: FetchConfig, options: AdditionalOptions = {}): Promise<T> => {
      const response = await this._executeRequest(path, fetchConfig, options);
      if (response.ok) {
        // handles possible null response that is sometimes returned for some API endpoints
        return response.text().then((text) => text ? JSON.parse(text) : null);
      } else {
        throw await this._handleResponseError(response, options);
      }
    };


    this._handleTextResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<string> => {
      const response = await this._executeRequest(path, fetchConfig, options);
      if (response.ok) {
        return response.text();
      } else {
        throw await this._handleResponseError(response, options);
      }
    };

    this._handleEmptyResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
      const response = await this._executeRequest(path, fetchConfig, options);
      if (response.ok) {
        return response;
      } else {
        throw await this._handleResponseError(response, options);
      }
    };

    this._handleBlobResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
      const response = await this._executeRequest(path, fetchConfig, options);
      if (response.ok) {
        const blob = await response.blob();
        downloadUtils.downloadBlob(blob, downloadUtils.extractFilenameFromResponse(response));
        return response;
      } else {
        throw await this._handleResponseError(response, options);
      }
    };
  }

  getWithJsonResponse = <T>(path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<T> => {
    return this._handleJsonResponse(path, {...fetchConfig, method: HttpMethod.GET}, options);
  };

  postWithJsonResponse = <T>(path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<T> => {
    return this._handleJsonResponse(path, {...fetchConfig, method: HttpMethod.POST}, options);
  };

  putWithJsonResponse = <T>(path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<T> => {
    return this._handleJsonResponse(path, {...fetchConfig, method: HttpMethod.PUT}, options);
  };

  patchWithJsonResponse = <T>(path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<T> => {
    return this._handleJsonResponse(path, {...fetchConfig, method: HttpMethod.PATCH}, options);
  };

  deleteWithJsonResponse = <T>(path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<T> => {
    return this._handleJsonResponse(path, {...fetchConfig, method: HttpMethod.DELETE}, options);
  };

  getWithTextResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<string> => {
    return this._handleTextResponse(path, {...fetchConfig, method: HttpMethod.GET}, options);
  };

  postWithTextResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<string> => {
    return this._handleTextResponse(path, {...fetchConfig, method: HttpMethod.POST}, options);
  };

  putWithTextResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<string> => {
    return this._handleTextResponse(path, {...fetchConfig, method: HttpMethod.PUT}, options);
  };

  patchWithTextResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<string> => {
    return this._handleTextResponse(path, {...fetchConfig, method: HttpMethod.PATCH}, options);
  };

  deleteWithTextResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<string> => {
    return this._handleTextResponse(path, {...fetchConfig, method: HttpMethod.DELETE}, options);
  };

  getWithEmptyResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleEmptyResponse(path, {...fetchConfig, method: HttpMethod.GET}, options);
  };

  postWithEmptyResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleEmptyResponse(path, {...fetchConfig, method: HttpMethod.POST}, options);
  };

  putWithEmptyResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleEmptyResponse(path, {...fetchConfig, method: HttpMethod.PUT}, options);
  };

  patchWithEmptyResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleEmptyResponse(path, {...fetchConfig, method: HttpMethod.PATCH}, options);
  };

  deleteWithEmptyResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleEmptyResponse(path, {...fetchConfig, method: HttpMethod.DELETE}, options);
  };

  getWithBlobResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleBlobResponse(path, {...fetchConfig, method: HttpMethod.GET}, options);
  };

  postWithBlobResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleBlobResponse(path, {...fetchConfig, method: HttpMethod.POST}, options);
  };

  putWithBlobResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleBlobResponse(path, {...fetchConfig, method: HttpMethod.PUT}, options);
  };

  patchWithBlobResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleBlobResponse(path, {...fetchConfig, method: HttpMethod.PATCH}, options);
  };

  deleteWithBlobResponse = async (path: string, fetchConfig: FetchConfig = {}, options: AdditionalOptions = {}): Promise<Response> => {
    return this._handleBlobResponse(path, {...fetchConfig, method: HttpMethod.DELETE}, options);
  };
}

export default RestApiHelper;
