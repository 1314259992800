// This needs to be imported by an consuming application in order for Font Awesome icons in React Kyber components to work.
// Any Font Awesome icons that React Kyber needs should be managed here.
// The consuming application can add additional icons to this library as well by importing this file.

// use the library component from Font Awesome to better access the icons across components
import {library} from '@fortawesome/fontawesome-svg-core';
// These icons are needed by React Kyber
import {
  faArrowDown,
  faArrowUp,
  faBell,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEye,
  faEyeSlash,
  faHome,
  faTh,
  faTimes,
  faTrash,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';

// Add the required icons to the Font Awesome library.
// This avoids having to explicitly import them in your components
library.add(
  faArrowDown,
  faArrowUp,
  faBell,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEye,
  faEyeSlash,
  faHome,
  faUserCircle,
  faTh,
  faTimes,
  faTrash
);

export default library;
