import React from 'react';

import Footer from './Footer';

const MiFooter = () => {
  const footerProps = {
    brand: 'State of Michigan',
    brandLink: 'https://www.michigan.gov/',
    footerLinks: [{
      name: 'Policies',
      to: 'https://www.michigan.gov/policies'
    }]
  };

  return (
    <Footer brand={footerProps.brand}
            brandLink={footerProps.brandLink}
            footerLinks={footerProps.footerLinks}/>
  );
};

export default React.memo(MiFooter);
