import React from 'react';

type Props = {
  brand: string
  brandLink: string
  footerLinks: {name: string, to: string}[]
}

const Footer = ({
                  brand,
                  brandLink,
                  footerLinks
                }: Props) => {

  const currentYear = new Date().getFullYear();

  const externalLinkProps = {
    target: '_blank',
    rel: 'noopener noreferrer'
  };

  return (
    <footer className="Footer d-flex justify-content-between">
      <div>
        <span className="footer-brand mr-1">Copyright {currentYear}</span>
        <a className="footer-brand" href={brandLink} {...externalLinkProps}>{brand}</a>
      </div>
      <ul className="footer-links d-flex justify-content-end">
        {footerLinks.map((footerLink) => {
          return (
            <li key={footerLink.name}>
              <a className={'footer-link'} href={footerLink.to} {...externalLinkProps}>{footerLink.name}</a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
};

export default React.memo(Footer);
