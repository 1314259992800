import React from 'react';

import BootstrapColor from '../types/BootstrapColor';

type Props = {
  className?: string
  color?: BootstrapColor
}

const ProgressIndicator = ({
                             className,
                             color = 'primary'
                           }: Props) =>
  (
    <div className={className ? `ProgressIndicator ${className}` : 'ProgressIndicator'}>
      <div className={`track bg-${color}`}/>
      <div className={`background ${color}`}/>
    </div>
  );

export default React.memo(ProgressIndicator);
