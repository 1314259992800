import React from 'react';
import {Button, ButtonProps} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BootstrapButtonSize} from '../types';

interface Props extends ButtonProps {
  ariaLabel: string;
  title: string;
  size?: BootstrapButtonSize;
  icon: IconProp;
  spinIcon?: boolean;
}

const ButtonIcon = ({
                      className,
                      ariaLabel,
                      title,
                      icon,
                      spinIcon,
                      size,
                      disabled,
                      onClick,
                      ...miscProps
                    }: Props) => {

  return (
    <Button color="link"
            className={className ? `ButtonIcon ${className}` : 'ButtonIcon'}
            aria-label={ariaLabel}
            title={title}
            size={size}
            disabled={disabled}
            onClick={onClick}
            {...miscProps}>
      <FontAwesomeIcon icon={icon} spin={spinIcon}/>
    </Button>
  );
};

export default React.memo(ButtonIcon);