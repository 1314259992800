import React from 'react';
import {Col, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';

export type LimitOption = {
  displayValue: string
  value: number | null
}

type Props = {
  className?: string
  message?: string
  resultLimit: number | null
  limitOptions?: LimitOption[]
  totalRecords: number
  handleClick: (limitOptionValue: number | null) => void
  recordName?: string
  pluralRecordName?: string
}

const ResultsLimiter = ({
                          className,
                          message = 'Show:',
                          resultLimit,
                          limitOptions = [
                            {displayValue: '50', value: 50},
                            {displayValue: '100', value: 100},
                            {displayValue: '250', value: 250},
                            {displayValue: 'All', value: null}
                          ],
                          totalRecords,
                          handleClick,
                          recordName = 'Record',
                          pluralRecordName = 'Records'
                        }: Props) => {
  const mainClassName = 'ResultsLimiter';

  const renderLimitOptions = () => {
    return limitOptions.map((limitOption, index) => {
      const selected = limitOption.value === resultLimit;
      if (selected) {
        return (
          <PaginationItem key={index}>
            <PaginationLink className="selected" aria-current="true" role="button">
              {limitOption.displayValue}
            </PaginationLink>
          </PaginationItem>
        );
      } else {
        return (
          <PaginationItem key={index}>
            <PaginationLink role="button" onClick={() => handleClick(limitOption.value)}>
              {limitOption.displayValue}
            </PaginationLink>
          </PaginationItem>
        );
      }
    });
  };

  const renderSummaryText = (resultLimit: number | null, total: number) => {
    if (total === 1) {
      return `${total} ${recordName}`;
    }
    if (!resultLimit || resultLimit >= total) {
      return `${total} ${pluralRecordName}`;
    }
    if (total > resultLimit) {
      return `Showing ${resultLimit} of ${total} ${pluralRecordName} `;
    }
  };

  return (
    <Row className={className ? `${mainClassName} ${className}` : mainClassName}>
      <Col className="d-flex justify-content-start pt-4 pl-d-5 col-12 col-sm-12 col-md-6">
        <p className="text-muted mb-0">{renderSummaryText(resultLimit, totalRecords)}</p>
      </Col>
      <Col className="d-flex justify-content-start justify-content-sm-start justify-content-md-end col-12 col-sm-12 col-md-6">
        <span className="text-muted py-4 pr-2">{message}</span>
        <Pagination className="text-muted pt-3 pr-4 mb-0">
          {renderLimitOptions()}
        </Pagination>
      </Col>
    </Row>
  );
};

export default React.memo(ResultsLimiter);
