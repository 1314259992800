import React from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

type Props = {
  breadcrumbs: {
    text: string
    icon?: IconProp
    route?: string
    active?: boolean
  }[]
}

const BreadcrumbsNav = ({
                          breadcrumbs
                        }: Props) =>
  (
    <Breadcrumb tag="nav" className="BreadcrumbsNav">
      {breadcrumbs.map((breadcrumb, index) => {
        const icon = breadcrumb.icon ? <FontAwesomeIcon icon={breadcrumb.icon} className="mr-2"/> : null;
        if (breadcrumb.active || !breadcrumb.route) {
          return (
            <BreadcrumbItem key={index} active>{icon}{breadcrumb.text}</BreadcrumbItem>
          );
        } else {
          return (
            <BreadcrumbItem key={index}>
              <Link to={breadcrumb.route}>{icon}{breadcrumb.text}</Link>
            </BreadcrumbItem>
          );
        }
      })}
    </Breadcrumb>
  );

export default React.memo(BreadcrumbsNav);
