import React, {ReactNode, useEffect, useState} from 'react';

import UserContext from './UserContext';
import {User, UserPermissions} from '../types';
import {SsoSpringRestApi} from '../restApi';
// Use default import SsoSessionExpirationModal to prevent circular dependency loading error
import SsoSessionExpirationModal from '../components/SsoSessionExpirationModal';

type Props = {
  children: ReactNode
  ssoApi: SsoSpringRestApi
  setPermissions?: (currentUser?: User) => UserPermissions
}

const UserProvider = ({
                        children,
                        ssoApi,
                        setPermissions
                      }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [loadingUser, setLoadingUser] = useState(true);
  const [isSessionExpirationModalOpen, setIsSessionExpirationModalOpen] = useState(false);
  const permissions = setPermissions ? setPermissions(currentUser) : {};

  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        await ssoApi.loginFromRefreshToken();
        const currentUser = await ssoApi.currentUser();
        setCurrentUser(currentUser);
        setLoadingUser(false);
      } catch (error) {
        await ssoApi.redirectToLogin();
      }
    };
    loadCurrentUser().then();
  }, [ssoApi, setCurrentUser, setLoadingUser]);

  // Setup session monitoring
  useEffect(() => {
    const interval = ssoApi.monitorSession(() => setIsSessionExpirationModalOpen(true));
    return () => clearInterval(interval);
  }, [ssoApi]);

  return (
    <UserContext.Provider value={{loadingUser, currentUser, permissions}}>
      {children}
      {currentUser &&
      <SsoSessionExpirationModal isOpen={isSessionExpirationModalOpen}
                                 setIsOpen={setIsSessionExpirationModalOpen}
                                 ssoApi={ssoApi}/>
      }
    </UserContext.Provider>
  );
};

export default UserProvider;