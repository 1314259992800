import React, {ReactNode, useState} from 'react';
import {CustomInput, FormFeedback, FormGroup, Label} from 'reactstrap';
import {useField, useFormikContext} from 'formik';

import {BootstrapFormControlSize} from '../types';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  id?: string
  name: string
  children: ReactNode
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  ariaLabel?: string
  icon?: {
    name?: string
    text?: string
  };
  disabled?: boolean
  disableOnSubmit?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  noFormikOnChange?: boolean
  invalid?: boolean
  value?: any
}

const FormikSelect = ({
                        id,
                        name,
                        children,
                        bsSize,
                        formGroupClass,
                        labelText,
                        ariaLabel,
                        icon,
                        invalid,
                        disabled,
                        disableOnSubmit = true,
                        onChange,
                        value,
                        noFormikOnChange,
                        ...otherProps
                      }: Props) => {
  const [focused, setFocused] = useState(false);
  const [field, meta] = useField(name);
  const {isSubmitting, validateOnMount} = useFormikContext();
  const isInvalid = validateOnMount ? !!meta.error : !!(meta.error && meta.touched);
  const idToUse = id ? id : `${name}Select`;

  const renderLabel = () => {
    if (labelText) {
      let labelClass = focused ? 'label-static focused' : 'label-static';
      labelClass = isInvalid ? `${labelClass} is-invalid` : labelClass;
      return (
        <Label htmlFor={idToUse}
               className={labelClass}
               size={bsSize}>
          {labelText}
        </Label>
      );
    }
    return null;
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Don't perform Formik's default on change if noFormikOnChange is true
    if (!noFormikOnChange) {
      await field.onChange(e);
    }

    // Call additional on change function if provided
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormGroup className={formGroupClass}
               onFocus={() => setFocused(true)}
               onBlur={() => setFocused(false)}>
      {renderLabel()}
      <CustomInput {...field}
                   {...otherProps}
                   type="select"
                   id={idToUse}
                   aria-label={ariaLabel ? ariaLabel : labelText}
                   bsSize={bsSize}
                   onChange={handleChange}
                   disabled={disabled || (isSubmitting && disableOnSubmit)}
                   invalid={invalid !== undefined ? invalid : isInvalid}
                   value={value !== undefined ? value : field.value}>
        {children}
      </CustomInput>
      <FormFeedback>{meta.error}</FormFeedback>
    </FormGroup>
  );
};

export default FormikSelect;