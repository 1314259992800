import React from 'react';
import {Button, ButtonProps} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BootstrapButtonSize} from '../types';

type ButtonState = {
  name: string
  text: string
  icon?: IconProp
  spinIcon?: boolean
}

interface Props extends ButtonProps {
  states: ButtonState[];
  activeState?: string;
  size?: BootstrapButtonSize;
}

const StatefulButton = ({
                          states,
                          activeState,
                          className,
                          ...miscProps
                        }: Props) => {
  // Set to first state if no active state is set
  let state;
  if (!activeState) {
    state = states[0];
  } else {
    state = states.find(state => {
      return state.name === activeState;
    });
    if (!state) {
      const validStates = states.map(state => {
        return state.name;
      });
      throw new Error(`${activeState} is not a valid state. Valid states are: ${validStates}`);
    }
  }

  return (
    <Button className={className} {...miscProps}>
      {state.icon &&
      <FontAwesomeIcon icon={state.icon} spin={state.spinIcon} className="mr-1"/>
      }
      {state.text}
    </Button>
  );
};

export default React.memo(StatefulButton);
