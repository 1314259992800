import React, {useCallback, useEffect} from 'react';

import {SsoSpringRestApi} from '../restApi';
import ConfirmationModal from './ConfirmationModal';
import {Duration, DurationType} from '../util';

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  ssoApi: SsoSpringRestApi
}

const SsoSessionExpirationModal = ({
                                     isOpen,
                                     setIsOpen,
                                     ssoApi
                                   }: Props) => {
  const checkActivity = useCallback(
    () => {
      // If open, but session data got cleared in another tab the close the modal, and go back to login
      if (isOpen && !ssoApi.sessionDataExists()) {
        ssoApi.redirectToLogin().then();
      }
      // If last activity got updated by a server request after modal and modal was open then close it
      if (isOpen && ssoApi.sessionTimeLeftGreaterThanWarningThreshold()) {
        setIsOpen(false);
      }
    },
    [isOpen, setIsOpen, ssoApi]
  );

  const confirmCallback = () => {
    ssoApi.bumpLastActivity();
    setIsOpen(false);
  };

  const cancelCallback = () => {
    setIsOpen(false);
  };

  useEffect(() => {
      // Check every 5 seconds
      const interval = setInterval(checkActivity, Duration.of(5, DurationType.SECONDS));
      // Clear interval if inputs change
      return () => clearInterval(interval);
    },
    [checkActivity]
  );

  return (
    <ConfirmationModal isOpen={isOpen}
                       title="Session Timeout"
                       confirmButtonText="Yes"
                       confirmButtonColor="success"
                       cancelButtonText="No"
                       cancelButtonColor="danger"
                       confirmCallback={confirmCallback}
                       cancelCallback={cancelCallback}>
      <p>Your session is expiring in less than 2 minutes. Would you like to continue?</p>
    </ConfirmationModal>
  );
};

export default React.memo(SsoSessionExpirationModal);