interface MimeTypes<T> {
  [Key: string]: T;
}

const DEFAULT_MIME_TYPES: MimeTypes<string[]> = {
  'CSV': ['text/csv', 'application/vnd.ms-excel'],
  'DOC': ['application/msword'],
  'DOCX': ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  'EML': ['message/rfc822'],
  'JPEG': ['image/jpeg'],
  'JPG': ['image/jpg'],
  'JSON': ['text/json', 'application/json'],
  'PDF': ['application/pdf'],
  'PNG': ['image/png'],
  'TXT': ['text/plain'],
  'XLS': ['application/vnd.ms-excel'],
  'XLSX': ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  'XLSM': ['application/vnd.ms-excel.sheet.macroenabled.12']
};

export default DEFAULT_MIME_TYPES;