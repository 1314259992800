import React from 'react';
import {FieldHelperProps} from 'formik';

import FormikInput from './FormikInput';
import {InputProps} from '../types';

type Props = InputProps & {
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormikFileInput = ({
                           name,
                           onChange,
                           ...formikInputProps
                         }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => {
    if (onChange) {
      onChange(e, fieldActions);
    } else {
      fieldActions.setValue(e.target?.files?.[0]);
    }
  };

  return <FormikInput {...formikInputProps}
                      type="file"
                      name={name}
                      onChange={handleChange}
                      format={() => undefined}
                      noFormikOnChange={true}/>;
};

export default FormikFileInput;