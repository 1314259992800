import * as Yup from 'yup';

import DEFAULT_MIME_TYPES from './defaultMimeTypes';

class YupFile {
  private _schema = Yup.mixed();

  maxFileSize(size: number, message: string) {
    this._schema = this._schema.test({
      name: 'maxFileSize',
      message,
      test: (file) => {
        return !file ? true : file && file.size <= size;
      }
    });

    return this;
  }

  acceptedFileTypes(fileTypes: string[] = [], message: string) {
    const allowedFileExtensions = fileTypes.map(fileType => '.' + fileType.toLowerCase());
    const mimeTypes = fileTypes.map(fileType => {
      return DEFAULT_MIME_TYPES[fileType] ? DEFAULT_MIME_TYPES[fileType] : fileType.toLowerCase();
    }).flat();

    this._schema = this._schema.test({
      name: 'acceptedFileTypes',
      message,
      test: (file) => {
        // Sometimes type is empty, so check by file extension if that happens to be the case
        if (file !== null && file !== undefined && !file.type) {
          return !allowedFileExtensions.every(fileExtension => file.name.toLowerCase().lastIndexOf(fileExtension) === -1);
        } else {
          return !file ? true : file && mimeTypes.includes(file.type.toLowerCase());
        }
      }
    });

    return this;
  }

  schema() {
    return this._schema;
  }
}

export default YupFile;