import React from 'react';
import {CustomInput} from 'reactstrap';

import {SelectTableCellData} from '../types';

type Props = {
  className?: string
  colspan?: number
  isHeader?: boolean
  htmlIdPrefix?: string // Use to avoid HTML id conflicts across tables, specify unique prefix for table and column
  itemId: number | string
  itemIds?: (number | string)[]
  selected: boolean
  onChange: (selectTableCellData: SelectTableCellData) => void
  ariaLabel?: string
}

const SelectTableCell = ({
                           className = 'align-middle text-center',
                           colspan,
                           isHeader = false,
                           htmlIdPrefix,
                           itemId,
                           itemIds = [],
                           selected,
                           onChange,
                           ariaLabel
                         }: Props) => {
  const Tag = isHeader ? 'th' : 'td';
  return (
    <Tag className={`SelectTableCell ${className}`}
         role={isHeader ? 'columnheader' : undefined}
         scope={isHeader ? 'col' : undefined}
         colSpan={colspan ? colspan : undefined}
         onKeyDown={(e) => e.key == 'Enter' ? onChange({itemId, itemIds, prevSelection: selected}) : null}>
      <CustomInput type="checkbox"
                   checked={selected}
                   id={htmlIdPrefix ? `${htmlIdPrefix}-${itemId}` : itemId}
                   aria-label={ariaLabel}
                   onChange={() => onChange({itemId, itemIds, prevSelection: selected})}/>
    </Tag>
  );
};

export default React.memo(SelectTableCell);