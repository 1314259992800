import React, {useEffect, useRef} from 'react';
import {Toast, ToastBody, ToastHeader} from 'reactstrap';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {useAlerts} from '../hooks';

const Alerts = () => {
  const {alerts, removeAlert} = useAlerts();

  const ref = useRef(null);
  useEffect(() => {
    const element = (ref?.current as unknown as HTMLElement);
    if (alerts.length > 0 && element !== null) {
      element.focus();
    }
  }, [alerts, ref]);

  return (
    <div className="Alerts">
      <TransitionGroup className="transition-list" component={null}>
        {alerts.map((alert, index) => {
            return (
              <CSSTransition key={index}
                             in={true}
                             timeout={300}
                             classNames="alert-transition">
                <Toast isOpen={true} fade={false} tabIndex={0} innerRef={ref}>
                  <ToastHeader toggle={() => removeAlert(alert.id)} icon={alert.icon}>
                    {alert.header}
                  </ToastHeader>
                  <ToastBody>
                    {alert.message}
                  </ToastBody>
                </Toast>
              </CSSTransition>
            );
          }
        )}
      </TransitionGroup>
    </div>
  );
};

export default Alerts;
