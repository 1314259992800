import React, {ReactNode} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {BootstrapColor, BootstrapModalSize} from '../types';

type Props = {
  isOpen: boolean
  title?: string
  size?: BootstrapModalSize
  confirmButtonText?: string
  confirmButtonColor?: BootstrapColor
  confirmCallback: (e: React.MouseEvent<HTMLButtonElement>) => void
  children: ReactNode
}

const AlertModal = ({
                      isOpen,
                      size,
                      title,
                      confirmButtonText = 'Ok',
                      confirmButtonColor = 'success',
                      confirmCallback,
                      children
                    }: Props) =>
  (
    <Modal isOpen={isOpen}
           size={size}
           backdrop="static">
      <ModalHeader className="h5" tag="h2">{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color={confirmButtonColor} onClick={confirmCallback}>{confirmButtonText}</Button>
      </ModalFooter>
    </Modal>
  );

export default React.memo(AlertModal);