import React, {KeyboardEvent, useState} from 'react';
import {FormFeedback, FormGroup, Label} from 'reactstrap';
import {useField, useFormikContext} from 'formik';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format, isValid, parse, parseISO} from 'date-fns';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import BootstrapFormControlSize from '../types/BootstrapFormControlSize';

type Props = {
  [key: string]: any
  id?: string
  name: string
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  ariaLabel?: string
  icon?: {
    name: IconProp
  };
  disabled?: boolean
  onChange?: (date: Date | string | null) => void
  noFormikOnChange?: boolean
  dateFormat?: string
  placeholderText?: string
  minDate?: Date
  maxDate?: Date
  showTodayButton?: boolean
  excludeDates?: Date[]
  filterDate?: (date: Date) => boolean
  ariaRequired?: boolean
}

const coerceToDate = (value: Date | string | null, dateFormat: string): Date | null => {
  if (value instanceof Date && isValid(value)) {
    return value;
  } else if (typeof value === 'string' && value.length >= dateFormat.length && isValid(parseISO(value))) {
    return parseISO(value);
  } else if (typeof value === 'string' && value.length >= dateFormat.length && isValid(parse(value, dateFormat, new Date()))) {
    return parse(value, dateFormat, new Date());
  } else {
    return null;
  }
};

const NUMBER_KEYS = Array(10).fill(0).map((_, i) => i.toString());

const safeParse = (value: string, dateFormat: string) => {
  if (value?.length !== dateFormat.length) {
    return value;
  } else if (isValid(parse(value, dateFormat, new Date()))) {
    return parse(value, dateFormat, new Date());
  } else {
    return value;
  }
};

const FormikDatePicker = ({
                            id,
                            name,
                            bsSize,
                            formGroupClass,
                            labelText,
                            ariaLabel,
                            icon,
                            disabled,
                            onChange,
                            noFormikOnChange,
                            dateFormat = 'MM/dd/yyyy',
                            placeholderText = 'mm/dd/yyyy',
                            minDate,
                            maxDate,
                            showTodayButton,
                            excludeDates,
                            filterDate,
                            ariaRequired,
                            ...otherProps
                          }: Props) => {
  const [field, meta, helpers] = useField(name);
  const {isSubmitting, validateOnMount} = useFormikContext();
  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const idToUse = id ? id : `${name}DatePicker`;
  const ariaLabelId = idToUse + '-label';
  const required = ariaRequired;
  const isInvalid = validateOnMount ? !!meta.error : !!(meta.error && meta.touched);
  const parsedDate = coerceToDate(field.value, dateFormat);
  const dateValue = isValid(parsedDate) && parsedDate instanceof Date ? format(parsedDate as Date, dateFormat) : field.value;

  const renderLabel = () => {
    if (labelText) {
      let labelClass = focused ? 'label-static focused' : 'label-static';
      labelClass = isInvalid ? `${labelClass} is-invalid` : labelClass;
      if (!icon) {
        return (
          <Label htmlFor={idToUse}
                 aria-hidden="true"
                 className={labelClass}
                 size={bsSize}>
            {labelText}
          </Label>
        );
      } else {
        return (
          <Label htmlFor={idToUse}
                 aria-hidden="true"
                 className={labelClass}
                 size={bsSize}>
            <FontAwesomeIcon icon={icon.name} size="xs"/> {labelText}
          </Label>
        );
      }
    }
    return null;
  };

  const handleTab = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    handleChange(safeParse(target.value, dateFormat));
    helpers.setTouched(true);
    // close calendar window on tab
    setOpen(false);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    handleChangeEnter(safeParse(target.value, dateFormat));
    helpers.setTouched(true);
    // close calendar window on enter
    setOpen(false);
  };

  const handleBackspace = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const {selectionStart, selectionEnd, value} = target;
    const selectionAtEnd = selectionStart === value.length;

    let newValue;
    if (selectionStart === 0 && selectionEnd === value.length) {
      // Handle backspace when all is highlighted
      newValue = '';
    } else if (selectionAtEnd && value[value.length - 1] === '/') {
      // handle backspaces at end of input if value ends in / remove it and previous number
      newValue = value.slice(0, value.length - 1);
    } else if (selectionAtEnd && value[value.length - 1] !== '/') {
      // handle backspaces at end of input if value does not end in /, just remove previous number
      newValue = value.slice(0, value.length - 1);
    } else {
      // handle backspace in middle of date
      newValue = value.slice(0, selectionStart || value.length) + value.slice(selectionEnd || 0, value.length);
    }

    target.value = newValue;
    handleChange(safeParse(newValue, dateFormat));
  };

  const handleTyping = (e: React.KeyboardEvent<HTMLInputElement>) => {
    helpers.setTouched(true);
    const target = e.target as HTMLInputElement;
    let value = target.value + e.key;
    let newValue = value;
    const [dayFormatLength, monthFormatLength] = dateFormat.split('/').map((format: string) => format.length);

    if (value.length === dayFormatLength && value.indexOf('/') === -1 && value[value.length - 1] !== '/') {
      e.preventDefault();
      // add a / if past days portion
      newValue = value + '/';
    } else if (value.length === (dayFormatLength + monthFormatLength + 1) && value.indexOf('/') !== -1 && value[value.length - 1] !== '/') {
      e.preventDefault();
      // add a / if past months portion
      newValue = value + '/';
    } else if (value.length >= dateFormat.length) {
      e.preventDefault();
      // handle user typing characters past maxLength(the dateFormat length)
      newValue = value.slice(0, dateFormat.length);
    }

    target.value = newValue;
    handleChange(safeParse(newValue, dateFormat));
  };

  const handleSlash = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    e.preventDefault();
    if (target.value.length > 0
      && target.value[target.value.length - 1] !== '/'
      && target.value.split('').length <= 3) {
      handleChange(safeParse(target.value + '/', dateFormat));
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    helpers.setTouched(true);

    if (e.key === 'Backspace') {
      handleBackspace(e);
    } else if (e.key === 'Tab') {
      handleTab(e);
    } else if (e.key === 'Enter') {
      handleEnter(e);
    } else if (NUMBER_KEYS.includes(e.key)) {
      handleTyping(e);
    } else if (e.key === '/') {
      handleSlash(e);
    }
  };

  const handleChange = (date: Date | string | null) => {
    if (onChange) {
      onChange(date);
    }

    if (!noFormikOnChange) {
      helpers.setValue(date);
    }
  };

  const handleChangeEnter = (date: Date | string | null) => {
    if (onChange) {
      setTimeout(() => onChange(date), 1);
    }

    if (!noFormikOnChange) {
      setTimeout(() => helpers.setValue(date), 1);
    }
  };

  const handleFocus = () => {
    setFocused(true);
    setOpen(true);
  };

  const handleBlur = () => {
    setFocused(false);
    setOpen(false);
  };

  const handleSelect = (date: Date) => {
    helpers.setTouched(true);
    setOpen(false);
    setFocused(false);
    handleChange(date);
  };

  return (
    <FormGroup className={formGroupClass}
               onFocus={handleFocus}>
      {/* react-datepicker doesn't support aria-label or ariaLabel prop, just we use ariaLabelledBy + a sr-only element for aria-label text */}
      <span id={ariaLabelId} className="sr-only">{ariaLabel ? ariaLabel : labelText + ' ' + placeholderText}</span>
      {renderLabel()}
      <DatePicker className={bsSize ? `form-control form-control-${bsSize}` : 'form-control'}
                  {...field}
                  {...otherProps}
                  id={idToUse}
                  value={dateValue}
                  disabled={disabled || isSubmitting}
        // react-datepicker props below
                  ariaLabelledBy={ariaLabelId}
                  selected={parsedDate}
                  open={open}
                  onBlur={handleBlur}
                  onSelect={handleSelect}
                  onKeyDown={handleKeyDown}
                  onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
                  onChange={(_, e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
                  dateFormat={dateFormat}
                  placeholderText={placeholderText}
                  required={required}
                  minDate={minDate}
                  maxDate={maxDate}
                  todayButton={showTodayButton ? 'Today' : null}
                  excludeDates={excludeDates}
                  filterDate={filterDate}/>
      <FormFeedback style={isInvalid ? {display: 'block'} : {display: 'none'}}>{meta.error}</FormFeedback>
    </FormGroup>
  );
};

export default FormikDatePicker;
